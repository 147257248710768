import { create } from 'zustand';
import clientCheck from '../../utils/clientCheck';

type createCookieConsent = {
	cookieConsentIsSetTo: boolean;
	cookieConsentBoxVisible: boolean;
	lastSet: string;
	enableSentry: boolean;
	setCookieConsent: (value: boolean, enableSentry?: boolean) => void;
	setCookieConsentBoxVisible: (value: boolean) => void;
};

const convertDate = (value: string | Date) => {
	const date = new Date(value);
	const day = date.getDate().toString().length === 1 ? '0' + date.getDate() : date.getDate();
	const month =
		(date.getMonth() + 1).toString().length === 1
			? '0' + (date.getMonth() + 1)
			: date.getMonth() + 1;
	const year = date.getFullYear();
	return `${day}.${month}.${year}`;
};

//* first load, check if cookie consent is set
try {
	// const nrsDSGVO = clientCheck() && JSON.parse(localStorage.getItem('nrs-dsgvo'));
	const nrsDSGVO = clientCheck() && JSON.parse(localStorage.getItem('nrs-dsgvo') || 'false');
	var tempCookie = false;
	var tempCookieBoxVisible = false;
	var tempEnableSentry = false;
	// const date = new Date(nrsDSGVO.lastSet);
	// const day = date.getDate().toString.length === 1 ? '0' + date.getDate() : date.getDate();
	// const month = (date.getMonth() + 1).toString.length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	// var lastSet = day + '.' + month + '.' + date.getFullYear();

	if (nrsDSGVO) {
		var lastSet = convertDate(nrsDSGVO.lastSet);
		tempEnableSentry = nrsDSGVO.enableSentry;

		if (nrsDSGVO.cookieConsentSet === true) {
			tempCookie = true;
		} else if (nrsDSGVO.cookieConsentSet === false) {
			tempCookie = false;
			//* if older than 2 months show cookie consent
			if (
				new Date().getTime() - new Date(nrsDSGVO.lastSet).getTime() >
				1000 * 60 * 60 * 24 * 30 * 2
			) {
				tempCookieBoxVisible = true;
			}
		}
	} else {
		clientCheck() && console.log('no cookie consent set');
	}
} catch (error) {
	console.log(error);
	// console.log('no cookie consent set');
}

export const useCookieConsent = create<createCookieConsent>((set, get) => ({
	// cookieConsentIsSetTo: false,
	// cookieConsentBoxVisible: false,
	// lastSet: false || '-',
	cookieConsentIsSetTo: tempCookie,
	cookieConsentBoxVisible: tempCookieBoxVisible,
	lastSet: lastSet || '-',
	enableSentry: tempEnableSentry,

	setCookieConsent: (value: boolean, enableSentry: boolean = false) => {
		set({ cookieConsentIsSetTo: value });
		set({ lastSet: convertDate(new Date()) });
		if (enableSentry) set({ enableSentry: true });

		localStorage.setItem(
			'nrs-dsgvo',
			JSON.stringify({ cookieConsentSet: value, lastSet: new Date(), enableSentry: enableSentry }),
		);
		// if (value) {
		// 	// document.cookie = `cookieConsentSet=${value}`;
		// } else {
		// 	const nowTwoOneMonth = new Date().setMonth(new Date().getMonth() + 2);
		// 	const expires = new Date(nowTwoOneMonth).toUTCString();
		// 	document.cookie = `cookieConsentSet=${value} ; expires=${expires}`;
		// }
	},
	setCookieConsentBoxVisible: (value: boolean) => {
		set({
			cookieConsentBoxVisible: value,
		});
	},
}));
